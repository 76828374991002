<template>
  <!-- 线上课程 -->
  <div class="wisdompracticeteaching">
    <div class="contain">
      <!-- <Mainpage></Mainpage> -->
      <router-view></router-view>
    </div>
  </div> 
</template>

<script>
import Mainpage from "@/views/wisdompracticeteaching/components/mainpage.vue";
export default {
  name: "Wisdompracticeteaching",
  components: {
    Mainpage,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.wisdompracticeteaching {
  // border: 1px solid red;
  background: #ffffff;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // .contain {
  //   // border: 1px solid red;
  //   width: 1760px;
  // }
}
</style>
